<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const props = withDefaults(defineProps<{
  imgUrl: string
  name: string
  createdAt: string | Date
  size: CfgTypographySizes.sm | CfgTypographySizes.md | CfgTypographySizes.xs
}>(), {
  imgUrl: '',
  name: '',
  createdAt: '',
  size: CfgTypographySizes.xs,
})

const values = reactive({
  imgUrl: props.imgUrl,
  name: props.name,
  createdAt: props.createdAt,
})

const size = ref(props.size)
</script>

<template>
  <div class="flex flex-row items-center gap-x-2">
    <div
      class="shrink-0"
      :class="{
        'size-[35px]': size === CfgTypographySizes.sm,
        'size-[35px] md:size-[47px]': size === CfgTypographySizes.md }"
    >
      <NuxtImg
        v-if="values?.imgUrl"
        :alt="$t('blog.card.author', { name: values?.name })"
        class="size-full rounded-full object-cover"
        format="avif,webp"
        height="35"
        loading="lazy"
        placeholder
        quality="50"
        :src="values.imgUrl"
        width="35"
      />
    </div>

    <div class="flex-col">
      <CfgTypography
        v-if="values?.name"
        class="line-clamp-1 shrink-0"
        rel="author"
        :size="size === CfgTypographySizes.xs ? CfgTypographySizes.xs : CfgTypographySizes.sm"
        tag-name="address"
      >
        <span
          class="not-italic"
          rel="author"
        >
          {{ values?.name }}
        </span>
      </CfgTypography>

      <CfgTypography
        v-if="values?.createdAt"
        class="shrink-0 text-black"
        :datetime="new Date(values.createdAt).toISOString()"
        :size="size"
        tag-name="time"
      >
        {{ $d(new Date(values.createdAt)) }}
      </CfgTypography>
    </div>
  </div>
</template>
