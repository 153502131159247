import type { PaginationByPage, Strapi4ResponseData } from '@nuxtjs/strapi'
import type { StrapiAttributesItem } from './../../types/strapi'
import type { BlogPost as RawPost } from './../../../admin/types/BlogPost'
import type { Issue, WithId } from '~/api/types'
import type { MaybeDeepReadonly } from '~/types/helpers'

export enum ShortPostKeys {
  title = 'title',
  slug = 'slug',
  description = 'description',
  createdAt = 'createdAt',
  category = 'category',
}

export enum ShortPortPopulate {
  author = 'author',
  authorPhoto = 'author.photo',
  poster = 'poster',
}

export enum BlogCategoriesEnum {
  BondReviews = 'Představení dluhopisů',
  MarketNews = 'Novinky trhu',
  BondsStatistics = 'Statistiky dluhopisů',
  Comments = 'Komentáře Vladimíra Pikory',
}

export type BlogPost = WithId<Pick<StrapiAttributesItem<
  RawPost['attributes']
>, ShortPostKeys | ShortPortPopulate.author | ShortPortPopulate.poster>>

export enum BlogPostDetailsComponentNames {
  Issues = 'content.issues',
  RichText = 'content.html-content',
}

export type IssueComponent = {
  id: number
  issues: {
    data: Omit<Strapi4ResponseData<Issue>, 'meta'>[]
  }
  __component: BlogPostDetailsComponentNames.Issues
}

export type RichTextComponent = {
  id: number
  html: string
  __component: BlogPostDetailsComponentNames.RichText
}

export type BlogPostDetails = Omit<RawPost['attributes'], 'content' | 'category'> & {
  content: Array<IssueComponent | RichTextComponent>
  category: BlogCategoriesEnum[]
  createdAt: string
  updatedAt: string
  publishedAt: string
}

export type FetchPostsPayload = {
  pagination: MaybeDeepReadonly<PaginationByPage>
  categories: MaybeDeepReadonly<BlogCategoriesEnum[]>
}
